import { AuthService } from './../services/auth/auth.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

import { CanActivate } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate  {
  constructor(public auth: AuthService) {}
 
  canActivate(): boolean {
  	if(this.auth.logged && this.auth.permisos==environment.opcionesPermisos[0])
    	return true;//this.auth.isAuthenticated();
  	return false;
	}
}