import { environment } from './../environments/environment';
import { ComunService } from './services/comun.service';
import { Component, enableProdMode } from '@angular/core';

import { Platform, NavController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AuthService } from './services/auth/auth.service';


enableProdMode();
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
})
export class AppComponent {
  paginas={
    ventas:{titulo: 'Ventas',url: '/ventas',icon: 'cart',class:"ventas"},
    creditos:{titulo: 'Creditos',url: '/creditos',icon: 'md-wallet',class:"creditos"},
    gastos:{titulo: 'Gastos',url: '/gastos',icon: 'ios-cafe',class:"gastos"},
    reportes:{titulo: 'Reportes',url: '/reportes',icon: 'paper',class:"reportes"},
    agregar:{titulo: 'Agregar Datos',url: '/agregar',icon: "clipboard",class:"agregar"},
    edicion:{titulo: 'Editar Datos',url: '/edicion',icon: 'create',class:"editar"},
    inventario:{titulo: 'Inventario',url: '/inventario',icon: 'folder-open',class:"inventario"},
    graficas:{titulo: 'Gráficas', url:'/graficas',icon:'analytics', class:"graficas"},
    configuracion:{titulo: 'Configuración', url:'/configuracion',icon:'build', class:"configuracion"},
  }
  
  public appPages = [];
  empresa:any=[];
  logged:boolean=false;
  constructor(private platform: Platform,private splashScreen: SplashScreen,
    private statusBar: StatusBar,private auth: AuthService,private navCtrl: NavController, private comun:ComunService
  ) {
    this.initializeApp();
    this.navCtrl.navigateRoot(['login']);
      this.auth.getSubscriptionAuth().subscribe(async(auth)=>{
          //limpieza de variables al iniciar
          this.auth.usuario=null;
         // this.usuario=null;
          this.appPages = [];
        if(auth == null)//si no esta logeado regresar a login
          {
            this.logged=this.auth.logged=false;
            this.navCtrl.navigateRoot(['login']);
            this.comun.usuario=null;
            this.comun.empresa=null;
            this.comun.permisos=null;
        // this.rootPage = 'HomePage';
          }else{
            let token= await this.auth.getToken();
            this.logged=this.auth.logged=true;//activar menu            
            this.empresa=this.comun.empresa=token.claims['empresa'];
            if(environment.empresa != this.empresa){
              await this.auth.logout();
              this.comun.alerta("Error al ingresar","credenciales incorrectas")
            }
            let permisos =this.comun.permisos = this.auth.permisos= token.claims['permisos'];
            if(permisos==environment.opcionesPermisos[0]){
              this.navCtrl.navigateRoot(['ventas']);     
              this.appPages = [
                          this.paginas.ventas,this.paginas.creditos,this.paginas.gastos,this.paginas.reportes,
                          this.paginas.agregar,this.paginas.edicion,this.paginas.inventario,this.paginas.graficas,
                          this.paginas.configuracion,
                          ];
              }else if(permisos==environment.opcionesPermisos[1]){
                this.navCtrl.navigateRoot(['ventas']);                              
                    this.appPages = [
                      this.paginas.ventas,this.paginas.creditos,this.paginas.gastos,
                      this.paginas.reportes,this.paginas.agregar,this.paginas.edicion,
                      this.paginas.inventario,this.paginas.graficas,
                          ];
              }else if(permisos==environment.opcionesPermisos[2]){
                this.navCtrl.navigateRoot(['ventas']);
                this.appPages = [                            
                  this.paginas.ventas,this.paginas.creditos,this.paginas.gastos,
                ];
              }else{
                await this.auth.logout();
                this.comun.alerta("Error al ingresar","Permisos Denegados")
              }
            //console.log(auth);                
             let sub =this.auth.getUsuario(auth.uid).subscribe(usr=>{
              if(usr.data()){
                this.comun.usuario=usr.data();
                this.auth.usuario=usr.data();
                }
              },err=>
              console.log('err: ', err),
              ()=>{
                sub.unsubscribe();
              })
            }
      });            
  }
  salir(){
    this.auth.logout();
  }
  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }
}
