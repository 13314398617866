import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './../services/auth/auth.service';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ModeradorGuard implements CanActivate {
	constructor(public auth: AuthService){}
  canActivate(): boolean {
  	if(this.auth.logged && (this.auth.permisos==environment.opcionesPermisos[0]|| this.auth.permisos==environment.opcionesPermisos[1])){
    	return true;//this.auth.isAuthenticated();
  	}
  	return false;
	}
  
}
