import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { AlertController, ToastController,LoadingController } from '@ionic/angular';
import { style, animate, trigger, transition } from '@angular/animations';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})

export class ComunService {
  camposEnteros:Array<string>=[]
  camposDouble:Array<string>=['costo','precio','existencias','limite']
  camposString:Array<string>=['nombre','marca','proveedor','tipo']
  public usuario:any;
  public permisos:any;
  public empresa:string;
  public correoPatch:string=environment.correoPatch;
  constructor(private alertCtrl:AlertController,private toastCtrl:ToastController,private loading:LoadingController) {
    
   }   
   /* EXTRACCION DE DATOS SUBS FIREBASE */
   /************obtener data + id de un query 'get' *************/
   extraerIdDatosGet(querySnap,camposACapitalizar=[]){
    let datos=[]
    querySnap.forEach(a => {
      const data =a.data() as any; 
      const id =a.id;     
      camposACapitalizar.map(key=>data[key]=this.capitalizar(data[key]))
      datos.push( {id,  ...data});
    });
    return datos
   }
   extraerIdDataSnapShot(documentSnap, camposACapitalizar=[]){
     let data=[];
     documentSnap.map(a => {
        const datos = a.payload.doc.data() as any;                 
        camposACapitalizar.map(key=>datos[key]=this.capitalizar(datos[key]))
        const id = a.payload.doc.id;
        let resEd = { id,  ...datos };
        data.push(resEd);
      });
    return data;
   }
  /* FIN EXTRACCION DE DATOS SUBS FIREBASE */
/* FORMATOS DATOS */
   /********* dar formato uppercase a los textos y numero a los numeros en strings */
   darFormato(data){
    for(let key in data){
      if(this.camposEnteros.includes(key)){
        data[key] = parseInt(data[key]);
      }
      else if(this.camposDouble.includes(key)){
        data[key] = Math.round(data[key] * 100) / 100 
      } 
      else if(this.camposString.includes(key)){
        data[key]=data[key].trim();//eliminar espacios al final y al principio
        data[key] = data[key].toString();
        try{data[key] = data[key].toUpperCase();}catch{err=>console.log(err)}
      }
     /* try{data[key] = data[key].toUpperCase();}catch{}
     if(!isNaN(data[key])) data[key] = data[key].toString();    */
    }
  }
  /********* formato de primera letra mayuscula para desplegar las tablas *********/
  capitalizar(str) 
  {    
    let original = str;
    try{     
      str = str.split(" ");
      for (var i = 0, x = str.length; i < x; i++) {
          str[i] = str[i][0].toUpperCase() + str[i].substr(1).toLowerCase();
      }      
      return str.join(' ');
      }catch{}      
    return original;
  }
/* FIN FORMATOS DATOS */ 
/*PERMISOS DE USUARIO */
admin(){
  return this.permisos==environment.opcionesPermisos[0];
  }
encargado(){
  return (this.permisos==environment.opcionesPermisos[0]||this.permisos==environment.opcionesPermisos[1]);
  }
/* FIN MENSAJES/ALERTAS  */
  async alertaToast(mensaje:string) {
    const toast = await   this.toastCtrl.create({      
      translucent:true,
      message: mensaje,
      duration:2200,
    });
    toast.present();
  }
  async alerta(titulo='',subTitulo='',mensaje='',tiempo=2000) {
    const alert = await this.alertCtrl.create({
      mode:'ios',
      header: titulo,
      subHeader: subTitulo,
      message: mensaje,
      buttons: ['OK']
    });
    await alert.present();
    setTimeout(()=>{try{alert.dismiss()}catch{}}, tiempo);
  }
  async alertaError(){
    const alert = await this.alertCtrl.create({
      header: "Error",
      subHeader: "Ha ocurrido un error inesperado",
      buttons: ['OK']
    });
    await alert.present();
    setTimeout(()=>{try{alert.dismiss()}catch{}}, 2000);
  }
  async eliminarAlert( nombreEliminado:string,callBack) {
    const alert = await this.alertCtrl.create({
      mode:'ios',
      header:"Advertencia",
      subHeader:"Esta a punto de eliminar: "+nombreEliminado,
      message:"Ya no podra buscar artículos por medio de este campo",
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          handler: data => {            
          }
        },
        {
          text: 'Aceptar',
          handler: data => {
            callBack();
          
          }
        }
      ]
    });
      await alert.present();
  }
  async alertaConOps(titulo:string="", text:string="",callback) {
    let alert = await this.alertCtrl.create({
      header:titulo,
      subHeader:text,
      mode:'ios',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          handler: data => {
              
          }
        },
        {
          text: 'Aceptar',
          handler: data => {
          callback();
          }
        }
      ]
    });
    alert.present();
  }
  
  async crearLoading(mensaje:string,duracion:number=0) {
    const loading = await this.loading.create({
      message: mensaje,
      translucent: true,
      duration: duracion,
      spinner:'dots',
    });
    return loading

  }
/* FIN MENSAJES/ALERTAS  */
/*NO COMUNES*/
  async alertaDeAbono(titulo:string="", text:string="",maximo,cancelar=false,callback) {
    let alert = await this.alertCtrl.create({
      header:titulo,
      subHeader:text,
      mode:'ios',
      backdropDismiss:false,
      inputs: [
        {
          name: 'abono',
          type: 'number',
          label: 'Abono:',
          value: '0',
          min:'0',
          max:maximo,
          placeholder:'Agrege el valor del abono'
        }
      ],
      buttons: cancelar? [
        {text: 'Cancelar',role: 'cancel',handler: data => {}},
        {text: 'Aceptar',handler: data => {callback(data);}}] :
        [{text: 'Aceptar',handler: data => {callback(data);}}] 
    });
    alert.present();
  }
/* FIN NO COMUNES*/
}

/**animacion Pagina y animacion Ngif */
export function animacionPag(){
  return trigger('animacionPagina', [      
        transition(':enter', [
          style({  opacity: 0 }),
          animate('1s ease-out', 
                  style({  opacity: 1 }))
        ]),
        transition(':leave',[
          style({  opacity: 1 }),
          animate('1s ease-in', 
                  style({ opacity: 0 }))
          ]),
          ])
    }
export function animacionIf(){  
   return trigger('animacionNgIf', [      
    transition(':enter', [
      style({ height: 0, opacity: 0 }),
      animate('.5s ease-out', 
              style({ height: 32, opacity: 1 }))
    ]),
    transition(':leave',          [
      style({ height: 32, opacity: 1 }),
      animate('.5s ease-in', 
              style({ height: 0, opacity: 0 }))
      ]),
      ])
  }
