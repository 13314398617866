import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './../services/auth/auth.service';
import { CanActivate } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LogedGuard implements CanActivate{
    constructor(public auth: AuthService) {}
 
  canActivate(): boolean {
  	if(this.auth.logged)
    	return true;
  	return false;
	}
}
