import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';
import { AngularFirestore, DocumentReference, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { snapshotChanges } from '@angular/fire/database';
import { take, first} from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Dictionary } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  empresa=environment.empresa
  rootEmpresa:AngularFirestoreDocument;
  marcas:any;
  proveedores=[];
  categorias=[];

  constructor(public db: AngularFirestore) { 
    this.rootEmpresa= db.collection('empresas').doc(this.empresa);
    this.getDatosCampos();
  }
  getUsuario(id){
    return this.rootEmpresa.collection('usuarios').doc(id).get();
  }
  /* OBTENER CAMPOS DE ITEMS "marcas, proveedroes, etc" */
  getDatosCampos(){
    this.rootEmpresa.collection("marca").snapshotChanges().subscribe(marcas=>{
      this.marcas=this.convertirAKeys(marcas);
    })
    this.rootEmpresa.collection("proveedor").snapshotChanges().subscribe(proveedor=>{
      this.proveedores=this.convertirAKeys(proveedor);
    })
    this.rootEmpresa.collection("categoria").snapshotChanges().subscribe(categoria=>{
      this.categorias=this.convertirAKeys(categoria);
    })
  }
  convertirAKeys(snapshot){
    let result=[]
    snapshot.map(data=>{
      const datos =  data.payload.doc.data() as any;
      const id = data.payload.doc.id;
      result[id]=datos;
    })
    return result
  }
  getFechaFirestore():firebase.firestore.FieldValue{
    return firebase.firestore.FieldValue.serverTimestamp();
   }
   /* FIN OBTENER CAMPOS DE ITEMS */
  /* busqueda de articulos */
  buscarItemsFullFiltros(nombre="",marca=null, categoria=null,proveedor=null,limite:number=40){ 		
    return  this.rootEmpresa.collection('articulos', ref => {
      //creacion de arreglo de busqueda dependiendo de los valores ingresados
        let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
        query=query.where('nombre','>=',nombre);
        //concatenar queries si vienen incluidas
        if( proveedor)query= query && query.where('proveedor','==',proveedor)
        if(categoria)query= query && query.where('categoria','==',categoria)
        if(marca)query=query&& query.where('marca','==',marca) 
        return query && query.orderBy('nombre','asc').limit(limite);
     }).snapshotChanges();	
 }
  public buscarPorCodigo(codigo,limite:number=40){
    return this.rootEmpresa.collection('articulos', ref => 			
            ref.where('codigo','==',codigo).limit(limite)).get();	
  }
  public buscarNit(nit,limite:number=10){
    return this.rootEmpresa.collection('clientes', ref =>
            ref.where('nit','>=',nit).limit(limite)).get();
  }
  public buscarCliente(nombre,limite:number=10){
    return this.rootEmpresa.collection('clientes', ref =>
            ref.where('nombre','>=',nombre).limit(limite)).get();
  }
  /* fin busqueda articulos */
  /* VENTAS */
  public addVenta(venta){
    let fecha= firebase.firestore.FieldValue.serverTimestamp();
    venta.fecha=fecha;
    console.log('venta: ', venta);
    return this.rootEmpresa.collection("ventas").add(venta);
  }
  public addCredito(credito){
    let fecha= firebase.firestore.FieldValue.serverTimestamp();
    credito.fecha=fecha;
    return this.rootEmpresa.collection("creditos").add(credito);
  }
  public addAbono(data){
    let fecha= firebase.firestore.FieldValue.serverTimestamp();
    data.fecha=fecha;
    return this.rootEmpresa.collection("abonos").add(data);
  }
  /*FIN VENTAS */
  /* creacion y obtencion de datos */
  crearItem(data){
    let fecha=firebase.firestore.FieldValue.serverTimestamp();
    data.fechaDeCreacion=fecha;
    data.fechaDeModificacion=fecha;
    return this.rootEmpresa.collection('articulos').add(data);
  }
  crearProveedor(data){
    return this.rootEmpresa.collection('proveedor').add(data);
  }
  getProveedores(){
    return this.rootEmpresa.collection('proveedor',ref => ref.orderBy('nombre',"desc")).valueChanges();
  }
  crearCategoria(data){
    return this.rootEmpresa.collection('categoria').add(data);
  }
  getCategorias(){
    return this.rootEmpresa.collection('categoria',ref => ref.orderBy('nombre',"desc")).valueChanges();
  }
  crearMarca(data){
    return this.rootEmpresa.collection('marca').add(data);
  }
  getMarcas(){
    return this.rootEmpresa.collection('marca',ref => ref.orderBy('nombre',"desc")).valueChanges();
  }
  getMedidas(){
    return this.rootEmpresa.collection('medida',ref => ref.orderBy('nombre',"desc")).valueChanges();
  }
  crearDataGenerica(coleccion:string,data){
    return this.rootEmpresa.collection(coleccion).add(data);
  }
    /* fin creacion y obtencion de articulos /*
  /* EDICION DATOS */
  buscarItemsEditar(nombre="",marca=null, categoria=null,proveedor=null,medida=null,limite:number=40){ 		
    return this.rootEmpresa.collection('articulos', ref => {
     let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      query=query.where('nombre','>=',nombre);
      if( proveedor)
        query= query && query.where('proveedor','==',proveedor)
      if(categoria)
        query= query && query.where('categoria','==',categoria)
      if(marca)
        query=query&& query.where('marca','==',marca) 
      if(medida)
        query=query&& query.where('medida','==',medida) 
     return query && query.orderBy('nombre','asc').limit(limite);
     }).get();	
  }
  getMarcasEditar(){
    return this.rootEmpresa.collection('marca',ref => ref.orderBy('nombre',"asc")).snapshotChanges();
  }
  getMedidasEditar(){
    return this.rootEmpresa.collection('medida',ref => ref.orderBy('nombre',"asc")).snapshotChanges();
  }
  getCategoriaEditar(){
    return this.rootEmpresa.collection('categoria',ref => ref.orderBy('nombre',"asc")).snapshotChanges();
  }
  getProveedorEditar(){
    return this.rootEmpresa.collection('proveedor',ref => ref.orderBy('nombre',"asc")).snapshotChanges();
  }
  editarDataGenerica(id:string,colleccion:string,data){
    let fechaDeModificacion=firebase.firestore.FieldValue.serverTimestamp();
    data.fechaDeModificacion=fechaDeModificacion;
    //agregar en entorno de produccion
    //data.usuarioModificador=this.user
    return this.rootEmpresa.collection(colleccion).doc(id).update(data);
  }
  
  eliminarItemGenerico(id:string,coleccion:string){
    //modificar en produccion para generar los datos del usuario que lo elimina
    //data.usuario=this.usr;
    //return this.rootEmpresa.collection("eliminaciones").doc("eliminarArticulo").update(data);
    return this.rootEmpresa.collection(coleccion).doc(id).delete();
  }
  public editarItem(id,data){
    return this.rootEmpresa.collection('articulos').doc(id).update(data);
  }	
  public buscarDocumentoGenerico(nombre,coleccion){
    return this.rootEmpresa.collection(coleccion, ref=> ref.where('nombre',"==",nombre)).snapshotChanges();
  }
  public getDocumentoGenerico(id,coleccion){
    return this.rootEmpresa.collection(coleccion).doc(id);
  }
  public getUtimasModificaciones(){
    return this.rootEmpresa.collection('articulos',ref=>ref.orderBy('fechaDeModificacion','desc').limit(15)).valueChanges();
  }
  /*VENTAS */
  getClientes(){
    return this.rootEmpresa.collection('clientes', ref=> ref.orderBy('nombre')).snapshotChanges();
  }
  /**AGREGAR PAGE */
  public getUtimosAgregados(){
    return this.rootEmpresa.collection('articulos',ref=>ref.orderBy('agregado','desc').limit(15)).valueChanges();
  }
  /************* FIN EDICION DATOS ****************/
  
  /* REPORTES */
  getVentas(initDay,endDay){
    return this.rootEmpresa.collection('ventas', 
          ref => ref.where('fecha','<',endDay).where('fecha','>',initDay).orderBy('fecha','desc')).get();
  }
  
  getVentasReporte(initDay,endDay){
    return this.rootEmpresa.collection('ventas', ref => ref.where('fecha','<=',endDay).where('fecha','>=',initDay).orderBy('fecha','desc')).snapshotChanges();
  }

  public borrarVenta(id){
   /* modificar produccion hacer un update a la db al documento "eliminarVetas"
     * para poder enviar el usuario que elimina la venta, parte de esta funcionalidad esta en functions/index.js ********/
     return this.rootEmpresa.collection('ventas').doc(id).delete();
    }
    getGastosReporte(initDay,endDay){
      return this.rootEmpresa.collection('gastos', ref => ref.where('fecha','<=',endDay).where('fecha','>=',initDay).orderBy('fecha','desc')).snapshotChanges();
    }
    getAbonosReporte(initDay,endDay){
      return this.rootEmpresa.collection('abonos', ref => ref.where('fecha','<=',endDay).where('fecha','>=',initDay).orderBy('fecha','desc')).snapshotChanges();
    }  
    getVentasNumero(numero){
      return this.rootEmpresa.collection('ventas', ref => ref.where('numeroVenta','==',numero).limit(1)).get();
    }
    getAbonosVentasNumero(numero){
      return this.rootEmpresa.collection('abonos', ref => ref.where('numeroVenta','==',numero)).get();
    }
  /*FIN REPORTES */
  /* CREDITOS */
  getCreditosActivos(clienteId=null){
    return this.rootEmpresa.collection('ventas', ref => {
      let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      query =clienteId ? 
        query.where('creditoPendiente','==',true).where('cliente.id','==',clienteId).orderBy('fecha','desc') :
        query.where('creditoPendiente','==',true).orderBy('fecha','desc');  
        return query}
    ).snapshotChanges();   
  }
  getAbonosCredito(id){
    return this.rootEmpresa.collection('abonos', ref=> ref.where('ventaId','==',id)).get();
  }
  crearAbono(abono){
    const fecha= firebase.firestore.FieldValue.serverTimestamp();
    abono.fecha=fecha;
    return this.rootEmpresa.collection('abonos').add(abono);
  }
  //unica operacion no atomica
  async saldarCredito(creditoId,dataAbono){
    const fecha= firebase.firestore.FieldValue.serverTimestamp();
    dataAbono.fecha=fecha;
    try{
      await this.rootEmpresa.collection('ventas').doc(creditoId).update({creditoPendiente:false})
      return await this.rootEmpresa.collection('abonos').add(dataAbono)
    }
    catch(err){
      return err;      
    }
  }
  /* FIN CREDITOS */
  /** INVENTARIO */
  getAllItems(filtroLimite=false){
    if (filtroLimite)
      return this.rootEmpresa.collection("articulos", ref => ref.where('sobreLimite','==',true)).get()
    else
      return this.rootEmpresa.collection("articulos").get()
  }
  getItemsFullFiltros(nombre="",marca=null, categoria=null,proveedor=null,medida=null,sobreLimite=false,limite:number=40){     		
    return this.rootEmpresa.collection('articulos', ref => {
      let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      query=query.where('nombre','>=',nombre);
      if( proveedor)
        query= query && query.where('proveedor','==',proveedor)
      if(categoria)
        query= query && query.where('categoria','==',categoria)
      if(marca)
        query=query&& query.where('marca','==',marca) 
      if(medida)
      query=query&& query.where('medida','==',medida) 
      if(sobreLimite)
        query=query&&query.where('sobreLimite','==',true);
        //console.log('query: ',query);
     return query && query.orderBy('nombre','asc').limit(limite);
     }).get();	
 }
  /** FIN INVENTARIO */
  /************************** GRAFICAS *************************/
	/* public getProductosMasVendidos(limite:number=25,){
    return this.rootEmpresa.collection('articulos',ref => ref.orderBy('ventasTotales','desc').limit(limite)).snapshotChanges();
  }*/
  getItemsSinVentas(limit=50){
    return this.rootEmpresa.collection('articulos', 
       ref => ref.where('ventasTotales','==',0).limit(limit)).get();
  }
  getItemsEstancados(fechaFiltro,limit:number=100){
    return this.rootEmpresa.collection('articulos', 
         ref => ref.where('ultimaVenta','<',fechaFiltro).limit(limit)).get();
  }
  public getHistorialItem(initDay, endDay,articuloId:string){
    return this.rootEmpresa.collection('articulos').doc(articuloId).collection('historial',ref=> ref.where('fecha','<',endDay).where('fecha','>',initDay).orderBy('fecha','asc')).get();
  }
  public getProductosMenosVendidos(limite:number=25){
    return this.rootEmpresa.collection('articulos',ref => ref.orderBy('ventasTotales','asc').limit(limite)).snapshotChanges();	
  }
  
  public getProductosMejoresGanancias(limite:number=25){
    return this.rootEmpresa.collection('articulos',ref => ref.orderBy('ganancias','desc').limit(limite)).snapshotChanges();	
  }
  public getProductosMenoresGanancias(limite:number=25){
    return this.rootEmpresa.collection('articulos',ref => ref.orderBy('ganancias','asc').limit(limite)).snapshotChanges();	
  }
  public buscarCodigo(codigo,limite:number=40){
    return this.rootEmpresa.collection('articulos', ref => 			
           ref.where('codigo','==',codigo).limit(limite)).snapshotChanges();	
  }
  getItems(marca=null, categoria=null,nombre="",limite:number=40){
    return this.rootEmpresa.collection('articulos', ref => {
     //creacion de arreglo de busqueda dependiendo de los valores ingresados sintaxis copiada
     let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      query=query.where('nombre','>=',nombre);
      if(marca)
        query=query && query.where('marca','==',marca);
      if(categoria)
        query=query && query.where('categoria','==',categoria);
      return query &&query.orderBy('nombre','asc').limit(limite);
     }).snapshotChanges();	
 }
 getGastosGraficas(initDay,endDay){
  return this.rootEmpresa.collection('gastos', ref => ref.where('fecha','<=',endDay).where('fecha','>=',initDay).orderBy('fecha','desc')).get();
}
    /************************** FIN GRAFICAS *************************/
/* CONFIGURACION */
getUsuarios(){
  return this.rootEmpresa.collection('usuarios',ref=> ref.orderBy('permisos','asc').orderBy('nombre','asc')).snapshotChanges()
}
buscarCorreo(correo){
  return this.rootEmpresa.collection('usuarios',ref=>ref.where('correo','==',correo)).get();
}
crearUsuario(usuario){
  return this.rootEmpresa.collection('usuarios').add(usuario);
}
borrarUsuario(id){
  return this.rootEmpresa.collection("usuarios").doc(id).delete();
}
    /******************funciones especiales ***************/
  /*  articulos:any=[]
     getArticulos(){
      this.articulos=[];
      console.log("obteniendoDatos")
       this.rootEmpresa.collection('articulos').get().subscribe(changes=>{
          changes.forEach(querySnap => {
          const data =querySnap.data() as any;                  
          //capitalizar campos requeridos para un mejor despliegue
          const id =querySnap.id;
          this.articulos.push({ id,  ...data });
          });
          console.log(this.articulos);
        });
      }
      modificarVentas(){
        return this.rootEmpresa.collection('ventas').get().subscribe(changes=>{
          changes.forEach(venta =>{
            var costo=0;
            var ventaData=venta.data();
            console.log('ventaData', ventaData)
            const id = venta.id;        
              console.log('id', id)
              ventaData.items.forEach(item =>{
                costo+=(item.costo*item.unidades);
              })
            ventaData.costo=costo;
            console.log('ventaData', ventaData)
            this.rootEmpresa.collection('ventas').doc(id).update(ventaData)
                .then(()=>{
                console.log('buenMiel')})
                .catch(
                  ()=>{                    
                    console.log('malMiel***********')
                  }
                )

          })
        });
      }
    modificarArticulos(){
      console.log("formateando");
      this.articulos.forEach(element => {
        //let data={};
        //const precio =+element.precio;
        //data['precio']=Math.floor((precio+4)*1.6);
        console.log(element);
        let estadoLimite:boolean;
        //if(+element.limite==0 || !element.limite)data['limite']=2;
        if(+element.existencias<=+element.limite) 
          {
            estadoLimite=true;
          }
          else{
            estadoLimite=false;
        };
        //if(element.proveedor=="")data['proveedor']="ANDRES SERRA";
        //console.log(data);
        this.rootEmpresa.collection('articulos').doc(element.id).update({
          sobreLimite:estadoLimite
        }).then((d)=>console.log(d)).catch(err=>console.log(err));
      })
      console.log("finito");
    }*/

    
}
