import { DataService } from 'src/app/services/data/data.service';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable } from 'rxjs';
export interface User{
  tipo:string,
  sueprUser:boolean,
  nombre:string,
  telefono:string
  }
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  auth:any;//info auth
  public usuario:any;//usuario 
  permisos:string;
  logged:boolean=false;
  usersCollection: Observable<any[]>;
  constructor(private firebaseAuth: AngularFireAuth, private data: DataService) {  }

  /* configuracion de logeo y usuarios */
	getSubscriptionAuth():Observable<any>{
	  return  this.firebaseAuth.authState;
	}
 	public getUsuario(id:string=""){
 		return this.data.getUsuario(id);
 	}
  public buscarUsuarioCorreo(correo){
  //  return this.db.collection('users', ref => ref.where('correo', '==',correo)).snapshotChanges()
      /*then(docSnapshot => {
        if (docSnapshot.exists) {
          // do something
        }*/
  }
	login(email: string="", password: string=""){
    return this.firebaseAuth.auth.signInWithEmailAndPassword(email, password);
  	}
  public getToken(){    
    return this.firebaseAuth.auth.currentUser.getIdTokenResult()    
    }
  logout() {
    return this.firebaseAuth
      .auth
      .signOut();
  }
}
