import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LogedGuard } from './guards/loged.guard';
import { AdminGuard } from './guards/admin.guard';
import { ModeradorGuard } from './guards/moderador.guard';
const routes: Routes = [
  { path: '',redirectTo: 'login', pathMatch: 'full' },
  { path: 'ventas', canActivate:[LogedGuard], loadChildren: './pages/ventas/ventas.module#VentasPageModule'},
  { path: 'creditos', canActivate:[LogedGuard], loadChildren: './pages/creditos/creditos.module#CreditosPageModule' },
  { path: 'gastos', canActivate:[LogedGuard],loadChildren: './pages/gastos/gastos.module#GastosPageModule' },
  { path: 'reportes',canActivate:[LogedGuard], loadChildren: './pages/reportes/reportes.module#ReportesPageModule' },
  { path: 'agregar', canActivate:[ModeradorGuard], loadChildren: './pages/agregar/agregar.module#AgregarPageModule'},
  { path: 'edicion', canActivate:[ModeradorGuard], loadChildren: './pages/edicion/edicion.module#EdicionPageModule' },
  { path: 'graficas',canActivate:[ModeradorGuard], loadChildren: './pages/graficas/graficas.module#GraficasPageModule' },
  { path: 'inventario',canActivate:[ModeradorGuard], loadChildren: './pages/inventario/inventario.module#InventarioPageModule' },
  { path: 'configuracion',canActivate:[AdminGuard], loadChildren: './pages/configuracion/configuracion.module#ConfiguracionPageModule' },
  { path: 'login', loadChildren: './pages/login/login.module#LoginPageModule' },


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
